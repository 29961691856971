import React, {useState, useEffect} from 'react'
import * as classes from './index.module.css'
import PageLink from '@ContentTypes/PageLink'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import Logos from "@Svg/logo.svg";
import { Link } from 'gatsby'

const Logo = ({location}) => {
	const data = {
		url: '/',
		title: ''
	}
	const [isScrolled, setIsScrolled] = useState(false);

  const checkScroll = () => {
    if (window.scrollY >= 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);
	return (
		<div className={classes.logo}>
			{(location?.pathname === '/') &&
				<h1>
					<Logos className={classes.logoImage} />
				</h1>
			}
			{(location?.pathname !== '/') &&
				<Link to={`/`} title="Greatsweb">
					<Logos className={classes.logoImage} />
				</Link>
			}
		</div>
	)
}

export default Logo

Logo.propTypes = {
  location: PropTypes.object
};