// extracted by mini-css-extract-plugin
export var aboutText = "index-module--aboutText--c0119 pt-4 pr-8";
export var col = "index-module--col--184d2 py-4 col-span-4 mOnly_col-span-12";
export var cont = "index-module--cont--373b0 index-module--cont--caa8c container mx-auto px-4";
export var contactIcon = "index-module--contactIcon--6ff2a w-4";
export var contactLink = "index-module--contactLink--f4d0e flex items-center gap-2";
export var contactLinkText = "index-module--contactLinkText--5d4af index-module--link--ab470 relative";
export var footer = "index-module--footer--de07c block w-full bg-white text-black overflow-x-clip pt-10 mt-10";
export var footerBottom = "index-module--footerBottom--69833 border-t border-gray py-4 text-darkGray text-sm";
export var footerLink = "index-module--footerLink--ecd24 index-module--link--ab470 relative leading-8 py-1";
export var footerTop = "index-module--footerTop--3f27b w-full pb-8";
export var row = "index-module--row--7f434 index-module--row--25056 grid gap-4 grid-cols-12 gap-8";
export var title = "index-module--title--ef3e4 border-b border-gray py-4 mb-4 col-span-12 text-lg uppercase block";