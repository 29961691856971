import React from 'react'
import Logo from '@Layout/Logo'
import Navigation from '@Layout/Navigation'
import * as classes from './index.module.css'
import PropTypes from 'prop-types'

const Header = ({location, navigation}) => {
	return (
		<header className={classes.root}>
			<div className={classes.header}>
				<div className={classes.cont}>
					<div className={classes.row}>
						<div className={classes.logoContainer}>
							<Logo location={location}/>
						</div>
						<div className={classes.navContainer}>
							<Navigation navigation={navigation}/>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header

Header.propTypes = {
  location: PropTypes.object
};