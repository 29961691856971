import React from 'react'
import Header from '@Layout/Header'
import Footer from '@Layout/Footer'
import Store from '@UseCase/store'
import { getClampValue } from '@UseCase/style'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

const Layout = ({ children, location }) => {
    const { contentfulHeader: { 
            navigation,  
        }} = useStaticQuery(
    graphql`
    query {
        contentfulHeader {
            ...Header
        }
    }
    `
    )
    return (
        <Store>
            <Helmet>
                <style type="text/css">{`
                    :root {
                        --5xl-font-size: ${getClampValue(90, 340)};
                        --4xl-font-size: ${getClampValue(60, 120)};
                        --3xl-font-size: ${getClampValue(30, 65)};
                        --2xl-font-size: ${getClampValue(20, 50)};
                        --xl-font-size: ${getClampValue(18, 30)};
                        --lg-font-size: ${getClampValue(18, 18)};
                    }
                `}</style>
            </Helmet>
            <Header location={location} navigation={navigation}/>
            <main>{children}</main>
            <Footer />
        </Store>
    );
}

export default Layout

Layout.propTypes = {
    location: PropTypes.object,
    children: PropTypes.element
};