import { Link } from 'gatsby'
import React from 'react'
import Logo from '../Logo'
import Envelope from '@Svg/envelope.svg'
import * as classes from './index.module.css'

const Footer = () => {

	return (
		<footer className={classes.footer}>
			<div className={classes.footerTop}>
				<div className={classes.cont}>
					<div className={classes.row}>
						<div className={classes.col}>
							<span className={classes.title}>About Us</span>
							<Logo/>
							<p className={classes.aboutText}>We're here to help you turn your vision into reality. We can assist with web development, mobile app creation, and data-driven digital marketing strategies – all designed to help you succeed.</p>
						</div>
						<div className={classes.col}>
							<span className={classes.title}>Quick Links</span>
							<ul>
								<li>
									<Link to={`/about-us`} className={classes.footerLink} title="About us">About us</Link>
								</li>
								<li>
									<Link to={`/privacy-policy`} className={classes.footerLink} title="Privacy policy">Privacy policy</Link>
								</li>
								<li>
									<Link to={``} className={classes.footerLink} title="Cookie policy">Cookie policy</Link>
								</li>
								<li>
									<Link to={`/terms-of-use`} className={classes.footerLink} title="Terms of use">Terms of use</Link>
								</li>
							</ul>
						</div>
						<div className={classes.col}>
							<span className={classes.title}>Contact us</span>
							<div className={classes.contactInfo}>
								<ul>
									<li>
										<a className={classes.contactLink} href="mailto:info@greatsweb.com" title='kepp in touch'>
											<Envelope className={classes.contactIcon}/>
											<span>info@greatsweb.com</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={classes.footerBottom}>
				<div className={classes.cont}>
					<span className={classes.copyrights}>All rights reserved. © 2024 greatsweb.com.</span>
				</div>
			</div>
		</footer>
	)
}

export default Footer

Footer.propTypes = {
};