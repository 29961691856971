module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Greatsweb","short_name":"Greatsweb","description":"We're here to help you turn your vision into reality. We can assist with web development, mobile app creation, and data-driven digital marketing strategies – all designed to help you succeed.","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"src/images/icons/favicon.png","icons":[{"src":"src/images/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/images/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/icons/icon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"src/images/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/images/icons/icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"src/images/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"src/images/icons/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/images/icons/icon-192x192.png","sizes":"196x196","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5dba099b093cf1c534493e0050be69dc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KGBTS7MK","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com/","routeChangeEventName":"gatsby-route-change","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
