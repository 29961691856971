import "./src/styles/global.css"
// On start redirect
export const onPrefetchPathname = ({ location, prevLocation }) => {
  	//console.log("Gatsby started to change location to", location.pathname)
  	//var event = new CustomEvent("startLoading", {detail: {}});
    //window.dispatchEvent(event);
}

// on first Init Ready
export const onPreRouteUpdate = () => {
  	var event = new CustomEvent("startLoading", {detail: {}});
    window.dispatchEvent(event);
}

export const onRouteUpdate = ({location, prevLocation}) => {
  	//console.log("We can show loading indicator now")
  	//setTimeout(function() {
	//}, 2000);
	  	var event = new CustomEvent("stopLoading", {detail: {}});
	    window.dispatchEvent(event);
}

// on first Init Ready
export const onInitialClientRender = () => {
  	var event = new CustomEvent("stopLoading2", {detail: {}});
    window.dispatchEvent(event);
}