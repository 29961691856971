import React from 'react'
import * as classes from './index.module.css'
import Spinner from '@Ui/Spinner'
import {Link} from 'gatsby'
import PropTypes from 'prop-types'

const Button = ({
	title, 
	style, 
	children, 
	loading,
	fullWidth,
	size,
	type,
	data,
	alignment,
	clickEvent,
	rootClasses,
	customColor,
	...props
}) => {
	let btnStyle = style ? classes[style.toLowerCase()] : '';
	const spin = loading ? <Spinner /> : null;
	btnStyle = fullWidth ? btnStyle.concat([' '], classes.fullWidth) : btnStyle;
	btnStyle = size ? btnStyle.concat([' '], classes[size.toLowerCase()]) : btnStyle;
	btnStyle = rootClasses ? btnStyle.concat([' '], rootClasses) : btnStyle;
	btnStyle = alignment ? btnStyle.concat([' '], classes[alignment.toLowerCase()]) : btnStyle;
	/*
	// onClick={e => handleEvent(e, data.url)}
	const handleEvent = (e, url) => {
		e.preventDefault();
		window.navLink = url;
		var event = new CustomEvent("startLoading", {detail: {}});
    	window.dispatchEvent(event);
	}
	*/

	return (
		<div className={classes.root}>
		{(type === 'button' || type === 'submit') && 
			<button 
				title={title} 
				type={type} 
				className={btnStyle} 
				onClick={clickEvent}
				style={customColor ? {variable: '--cs-color', '--cs-color': customColor} : null} 
				data-button
				{...props}
			>
				{spin}
				{children}
			</button>
		}
		{(type === 'link') &&
			<Link
			    to={data.url}
			    className={btnStyle}
			    data-button
			    style={customColor ? {variable: '--cs-color', '--cs-color': customColor} : null}
			  >
			    {children && children}
			    {!children && data.title}
			</Link>
		}
		</div>
	)
}

export default Button

Button.propTypes = {
	data: PropTypes.object,
  	title: PropTypes.string,
  	style: PropTypes.string,
  	loading: PropTypes.bool,
  	fullWidth: PropTypes.bool,
  	size: PropTypes.string,
  	type: PropTypes.string,
  	alignment: PropTypes.string,
  	clickEvent: PropTypes.func,
  	children: PropTypes.element,
};