import React, { useReducer, createContext, useContext } from 'react'
import PropTypes from 'prop-types'

export const StoreContext = createContext()
StoreContext.displayName = 'StoreContext'

const initialState = {
  navOpenState: false,
  loadingState: false
}

function storeReducer(state, action) {
  switch (action.type) {

    case 'SET_NAV_STATE': {
      return {
        ...state,
        navOpenState: action.progress,
      }
    }

    case 'NAV_STATE': {
      return {
        ...state,
        navOpenState: state.navOpenState,
      }
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loadingState: action.progress,
      }
    }

    case 'LOADING': {
      return {
        ...state,
        loadingState: state.loadingState,
      }
    }

    default:
      throw new Error('Bad action type')
  }
}

export const navState = dispatch => dispatch({ type: 'NAV_STATE' })
export const setNavState = (dispatch, progress) => dispatch({ type: 'SET_NAV_STATE', progress })
export const loading = dispatch => dispatch({ type: 'LOADING' })
export const setLoading = (dispatch, progress) => dispatch({ type: 'SET_LOADING', progress })

function StoreProvider(props) {
  const [store, dispatch] = useReducer(storeReducer, initialState)
  const value = [store, dispatch]

  return <StoreContext.Provider value={value} {...props} />
}

export function useStore() {
  const context = useContext(StoreContext)

  if (!context) {
    throw new Error('useStore must be used within a StoreProvider')
  }

  return context
}

function Store(props) {
  const {children} = props;
  return <StoreProvider>{children}</StoreProvider>
}

Store.propTypes = {
    children: PropTypes.array,
};

export default Store
