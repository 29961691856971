import React, {useState, useEffect} from 'react'
import * as classes from './index.module.css'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Menu from '@Svg/menu.svg'

const Navigation = ({navigation}) => {
	const [state, setState] = useState();
	const toggleClass = state ? classes.navOpend : classes.nav;

	const getInTouch = {
		url: '/contact',
		title: ''
	}


	return (
		<>
			<button className={classes.toggle} onClick={() => setState(!state)} title="Main menu" aria-label='Main menu'><Menu className={classes.menuIcon}/></button>
			<nav className={toggleClass}>
				<ul className={classes.linksList}>
					{navigation && navigation.map((item, index) => (
						<li className={classes.navItem} key={index} data-item="">
							<Link to={item.url} className={classes.link} title={item.title}>{item.title}</Link>
						</li>
					))}
				</ul>
			</nav>
		</>
	)
}

export default Navigation

Navigation.propTypes = {
  navigation: PropTypes.arrayOf(PropTypes.object),
  linkedinUrl: String,
  instagramUrl: String,
  tiktokUrl: String,
  threadsUrl: String
};