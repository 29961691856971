// extracted by mini-css-extract-plugin
export var button = "index-module--button--cd93e text-[20px] px-8 h-[44px] border rounded primary-anime inline-flex items-center gap-1 justify-center whitespace-nowrap min-w-40";
export var center = "index-module--center--00ac0 mx-auto";
export var disabled = "index-module--disabled--6383f index-module--button--cd93e text-[20px] px-8 h-[44px] border rounded primary-anime inline-flex items-center gap-1 justify-center whitespace-nowrap min-w-40 bg-gray text-default border-gray pointer-events-none";
export var fullWidth = "index-module--fullWidth--86100 w-full";
export var light = "index-module--light--9f4ae index-module--button--cd93e text-[20px] px-8 h-[44px] border rounded primary-anime inline-flex items-center gap-1 justify-center whitespace-nowrap min-w-40 bg-white text-black border-white hover_bg-secondary hover_border-secondary";
export var linner = "index-module--linner--d15f3 index-module--button--cd93e text-[20px] px-8 h-[44px] border rounded primary-anime inline-flex items-center gap-1 justify-center whitespace-nowrap min-w-40 bg-white text-default border-gray hover_bg-default hover_text-white";
export var primary = "index-module--primary--2d00b index-module--button--cd93e text-[20px] px-8 h-[44px] border rounded primary-anime inline-flex items-center gap-1 justify-center whitespace-nowrap min-w-40 bg-primary text-white border-primary hover_border-secondary hover_bg-secondary hover_text-primary";
export var right = "index-module--right--9b783 ml-auto";
export var root = "index-module--root--a179f flex flex-wrap";
export var secondary = "index-module--secondary--53ee5 index-module--button--cd93e text-[20px] px-8 h-[44px] border rounded primary-anime inline-flex items-center gap-1 justify-center whitespace-nowrap min-w-40 bg-secondary text-black border-secondary hover_bg-white hover_text-secondary";
export var xl = "index-module--xl--fd877 text-xl px-8 py-4";