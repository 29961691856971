import React from 'react'
import Button from "@Ui/Button"
import PropTypes from 'prop-types'

const PageLink = ({data, children, style, rootClasses, customColor}) => {
	const {
		style: dataStyle,
		alignment
	} = data;
	return (
		<Button 
			type="link" 
			rootClasses={rootClasses} 
			style={dataStyle ? dataStyle : style} 
			data={data} 
			alignment={alignment}
			customColor={customColor}
		>{children}</Button>
	)
}
export default PageLink

PageLink.propTypes = {
	data: PropTypes.object,
	children: PropTypes.element,
	style: PropTypes.string,
  	dataStyle: PropTypes.object,
  	alignment: PropTypes.string
};